import indexStyles from '../sass/index.scss';
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
import sliderImg1 from "../img/slider-image-1-optimized.jpg";
import sliderImg2 from "../img/slider-image-2-optimized.jpg";
import projectImg1 from "../img/project-1.jpg";
import projectImg2 from "../img/project-2.jpg";
import projectImg3 from "../img/project-3.jpg";
import poinstBkg4k from "../img/points-background-2560.png";
import newsBkg from "../img/news-background.png";
import accentsImg1 from "../img/accent-1.jpg";
import accentsImg2 from "../img/accent-2.jpg";

function mainSliderInit() {
    const sliderHolderContainer = document.getElementsByClassName('main-slider-holder')[0];
    if (sliderHolderContainer === undefined || !sliderHolderContainer) {
        console.log('mainSliderInit: no sliderHolderContainer found by class "main-slider-holder"')
        return false;
    }
    const sliderContainer = sliderHolderContainer.getElementsByClassName('main-slider')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('mainSliderInit: no sliderContainer found by class "main-slider"')
        return false;
    }
    const sliders = sliderHolderContainer.getElementsByClassName('slider');
    const prevButtonContainer = sliderHolderContainer.getElementsByClassName('prev')[0];
    const nextButtonContainer = sliderHolderContainer.getElementsByClassName('next')[0];
    if (prevButtonContainer === undefined || !prevButtonContainer) {
        console.log('mainSliderInit: no prevButtonContainer found by class "prev"')
        return false;
    }
    if (nextButtonContainer === undefined || !nextButtonContainer) {
        console.log('mainSliderInit: no nextButtonContainer found by class "next"')
        return false;
    }
    var slider = tns({
        container: sliderContainer,
        items: 1,
        autoplay: false,
        slideBy: 'page',
        prevButton: prevButtonContainer,
        nextButton: nextButtonContainer,
        rewind: true,
        nav: false
    });
}

function projectsSliderInit() {
    const sliderHolderContainer = document.getElementsByClassName('projects-viewport')[0];
    if (sliderHolderContainer === undefined || !sliderHolderContainer) {
        console.log('projectsSliderInit: no sliderHolderContainer found by class "projects-viewport"')
        return false;
    }
    const sliderContainer = sliderHolderContainer.getElementsByClassName('projects-slider')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('projectsSliderInit: no sliderContainer found by class "projects-slider"')
        return false;
    }
    const sliders = sliderHolderContainer.getElementsByClassName('slider');
    const prevButtonContainer = sliderHolderContainer.getElementsByClassName('prev')[0];
    const nextButtonContainer = sliderHolderContainer.getElementsByClassName('next')[0];
    if (prevButtonContainer === undefined || !prevButtonContainer) {
        console.log('projectsSliderInit: no prevButtonContainer found by class "prev"')
        return false;
    }
    if (nextButtonContainer === undefined || !nextButtonContainer) {
        console.log('projectsSliderInit: no nextButtonContainer found by class "next"')
        return false;
    }
    var slider = tns({
        container: sliderContainer,
        items: 1,
        autoplay: false,
        slideBy: 'page',
        prevButton: prevButtonContainer,
        nextButton: nextButtonContainer,
        rewind: true,
        nav: false,
    });
}

function accentSliderInit() {
    const sliderHolderContainer = document.getElementsByClassName('accents-viewport')[0];
    if (sliderHolderContainer === undefined || !sliderHolderContainer) {
        console.log('accentSliderInit: no sliderHolderContainer found by class "accents-viewport"')
        return false;
    }
    const sliderContainer = sliderHolderContainer.getElementsByClassName('accents-slider')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('accentSliderInit: no sliderContainer found by class "accents-slider"')
        return false;
    }
    const sliders = sliderHolderContainer.getElementsByClassName('slider');
    const prevButtonContainer = sliderHolderContainer.getElementsByClassName('prev')[0];
    const nextButtonContainer = sliderHolderContainer.getElementsByClassName('next')[0];
    if (prevButtonContainer === undefined || !prevButtonContainer) {
        console.log('accentSliderInit: no prevButtonContainer found by class "prev"')
        return false;
    }
    if (nextButtonContainer === undefined || !nextButtonContainer) {
        console.log('accentSliderInit: no nextButtonContainer found by class "next"')
        return false;
    }
    var slider = tns({
        container: sliderContainer,
        items: 1,
        autoplay: false,
        slideBy: 'page',
        prevButton: prevButtonContainer,
        nextButton: nextButtonContainer,
        rewind: true,
        nav: false,
    });
}


document.addEventListener('DOMContentLoaded', function(){
    mainSliderInit();
    projectsSliderInit();
    accentSliderInit();
});
